import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import phone from '../images/iphone.png';
import screen from '../images/screen.png';
import Pass from '../snippets/pass';
import Loading from '../snippets/loading';
import Design from './pass/design';
import Products from './pass/products';
import Back from './pass/back';
import { generatePDF } from '../utils/pdf';

class PassDesigner extends Component {
  static propTypes = {
    company: PropTypes.shape({}),
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
      save: false,
      loading: false,
      front: true,
      company: null,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.changeState = this.changeState.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  async componentDidMount() {
    const response = await fetch('https://api.passpoints.de/api/companies/me', {
      method: 'GET',
      headers: {
        token: this.props.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = await response.json();

    this.setState(body);
  }

  componentWillUnmount() {
    if (this.state.save === true) {
      if (window.confirm('Wollen Sie die Änderungen speichern?')) {
        Alert.success('Änderungen gespeichert', {
          position: 'top',
          effect: 'stackslide',
          timeout: 2000,
        });
      }
    }
  }

  handleSelect(index, last) {
    console.log(`Selected tab: ${index}, Last tab: ${last}`);
    this.setState({ tab: index });
    if (index === 0) {
      this.setState({ front: true });
    } else {
      this.setState({ front: false });
    }
  }

  changeState(state) {
    this.setState(state);
    this.setState({ save: true });
  }

  generatePDF() {
    const company = this.state.company;
    this.setState({ loading: true });
    Alert.info('PDF wird generiert', {
      position: 'top',
      effect: 'stackslide',
      timeout: 2000,
    });

    generatePDF(company, err => {
      this.setState({ loading: false });
    });
  }

  async saveChanges() {
    if (this.state.save !== true) return;

    this.setState({ save: true });

    if (this.state.cover) {
      const formData = new FormData();
      formData.append('image', this.state.cover.file);

      await fetch('https://api.passpoints.de/api/companies/images/strip', {
        method: 'POST',
        headers: {
          token: this.props.token,
        },
        body: formData,
      });
    }

    if (this.state.logo) {
      const formData = new FormData();
      formData.append('image', this.state.logo.file);

      await fetch('https://api.passpoints.de/api/companies/images/logo', {
        method: 'POST',
        headers: {
          token: this.props.token,
        },
        body: formData,
      });
    }

    const response = await fetch('https://api.passpoints.de/api/companies/me', {
      method: 'PUT',
      headers: {
        token: this.props.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company: {
          ...this.state.company,
          bonuspass: {
            ...this.state.company.bonuspass,
            strip: null,
            logo: null,
          },
        },
      }),
    });

    if (response.status < 299) {
      this.setState({ save: false });
    } else {
      const body = await response.json();
      Alert.error(`Fehler beim speichern (${body.error.displayMessage})`, {
        position: 'top',
        effect: 'stackslide',
        timeout: 2000,
      });
    }
  }

  render() {
    const { company } = this.state;

    if (!company) {
      return <Loading loading overlay />;
    }

    console.log(company);

    return (
      <div className="wrapper">
        <Loading loading={this.state.loading} overlay />
        <h1>Ihr Pass</h1>
        <div className="button-wrapper right">
          <a
            href={`https://passpoints.de/pass/${this.state.company.shortname}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Pass Seite"
          >
            <button className={'pass-button'}>Zur Pass-Seite</button>
          </a>
          <button
            className={this.state.save ? 'save-button' : 'save-button inaktive'}
            onClick={this.saveChanges}
          >
            Speichern
          </button>
        </div>
        <div className="grid gutter">
          <div className="grid-item x40">
            <div className="box">
              <button className="print-button" onClick={this.generatePDF} />
              <Link to={'/themes'} title="Theme wählen">
                <div className="theme-button" />
              </Link>
              <div className="phone-box">
                <img src={phone} alt="Pass Designer" className="phone" />
                <img src={screen} alt="Wallet App" className="screen" />
                <Pass
                  manual
                  front={this.state.front}
                  background={this.state.company.bonuspass.backgroundColor}
                  color={this.state.company.bonuspass.foregroundColor}
                  title={this.state.company.name}
                  showTitle={this.state.company.showName}
                  cover={
                    (this.state.cover && this.state.cover.preview) ||
                    `${
                      this.state.company.bonuspass.strip
                    }?t=${new Date().getTime()}`
                  }
                  logo={
                    (this.state.logo && this.state.logo.preview) ||
                    `${
                      this.state.company.bonuspass.logo
                    }?t=${new Date().getTime()}`
                  }
                  informations={this.state.company.bonuspass.backfield}
                  pushTitle={
                    this.state.company.bonuspass.action &&
                    this.state.company.bonuspass.action.title
                  }
                  pushMessage={
                    this.state.company.bonuspass.action &&
                    this.state.company.bonuspass.action.message
                  }
                  qr={`https://passpoints.de/api/passes/${
                    this.state.company.qrCodeID
                  }/new`}
                  changeState={this.changeState}
                />
              </div>
            </div>
          </div>
          <div className="grid-item x60">
            <div className="box">
              <Tabs
                onSelect={this.handleSelect}
                selectedIndex={this.state.tab}
                className={`tab-${this.state.tab}`}
              >
                <TabList>
                  <Tab>Design</Tab>
                  <Tab>Rückseite</Tab>
                  <Tab>Produkte</Tab>
                  <div className="ReactTabs__Line" />
                </TabList>
                <TabPanel>
                  <Design state={this.state} changeState={this.changeState} />
                </TabPanel>
                <TabPanel>
                  <Back state={this.state} changeState={this.changeState} />
                </TabPanel>
                <TabPanel>
                  <Products state={this.state} changeState={this.changeState} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function select(state) {
  return { token: state.login.token };
}

export default connect(select)(PassDesigner);
