import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class Mail extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    const response = await fetch('https://api.passpoints.de/api/companies/me', {
      method: 'GET',
      headers: {
        token: this.props.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = await response.json();
    this.setState({ ...body.company.emailOptions });
  }

  save = async event => {
    event.preventDefault();

    this.setState({ save: true });

    const response = await fetch('https://api.passpoints.de/api/companies/me', {
      method: 'PUT',
      headers: {
        token: this.props.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company: {
          emailOptions: this.state,
        },
      }),
    });

    if (response.status < 299) {
      this.setState({ save: false });
    }
  };

  render() {
    const { from, subject, text } = this.state;

    if (!from) {
      return null;
    }

    return (
      <form>
        <div className="grid gutter form-grid">
          <div className="grid-item x100">
            <div className="info-box">
              <p>
                Wenn Sie wünschen können Sie hier die E-Mail Adresse Ihres
                Unternehmens eingeben. Diese wird dann als Absender benutzt,
                wenn ein Kunde sich einen Pass per E-Mail schicken lässt.
              </p>
            </div>
            <fieldset>
              <h3>Sender</h3>
              <input
                value={from}
                onChange={e => this.setState({ from: e.target.value })}
              />
            </fieldset>
          </div>
          <div className="grid-item x100">
            <fieldset>
              <h3>Betreff</h3>
              <input
                value={subject}
                onChange={e => this.setState({ subject: e.target.value })}
              />
            </fieldset>
          </div>
          <div className="grid-item x100">
            <fieldset>
              <h3>Nachricht</h3>
              <textarea
                value={text}
                onChange={e => this.setState({ text: e.target.value })}
              />
            </fieldset>
          </div>
        </div>
        <div className="right">
          <button
            onClick={this.save}
            className={this.state.save ? 'save-button inaktive' : 'save-button'}
          >
            Speichern
          </button>
        </div>
      </form>
    );
  }
}

function select(state) {
  return { token: state.login.token };
}

export default connect(select)(Mail);
