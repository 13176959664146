export const LOGGED_IN = 'LOGGED_IN';
export const LOGOUT = 'LOGOUT';

export function login(token) {
  return {
    type: LOGGED_IN,
    token,
  };
}

export function logout() {
  window.localStorage.clear();

  return {
    type: LOGOUT,
  };
}
