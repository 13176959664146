import React, { Component } from 'react';

class Plans extends Component {
  render() {
    const { register, plan } = this.props;

    return (
      <div>
        <div
          className={plan === 'pp_small' ? 'pricing active' : 'pricing'}
          onClick={() => {
            this.props.changePlan('pp_small');
          }}
        >
          <h3>small</h3>
          <p>1.000 Aktive Pässe - keine Push-Nachrichten - Basic Statistiken</p>
          <p className="price">
            29€
            <br />
            <small>Pro Monat</small>
          </p>
          <span />
        </div>
        <div
          className={plan === 'pp_medium' ? 'pricing active' : 'pricing'}
          onClick={() => {
            this.props.changePlan('pp_medium');
          }}
        >
          <h3>medium</h3>
          <p>2.000 Aktive Pässe - 2 Push-Nachrichten - Pro Statistiken</p>
          <p className="price">
            49€
            <br />
            <small>Pro Monat</small>
          </p>
          <span />
        </div>
        <div
          className={plan === 'pp_large' ? 'pricing active' : 'pricing'}
          onClick={() => {
            this.props.changePlan('pp_large');
          }}
        >
          <h3>large</h3>
          <p>10.000 Aktive Pässe - 4 Push-Nachrichten - Pro Statistiken</p>
          <p className="price">
            99€
            <br />
            <small>Pro Monat</small>
          </p>
          <span />
        </div>
        <div className="info-box">
          <p>
            Keine lange Vertragslaufzeit, Sie können jeden Monat mit einem Klick
            kündigen.
          </p>
          <button
            className={plan !== 'none' && register !== true ? '' : 'hide'}
            style={{ marginTop: '20px' }}
            onClick={() => {
              this.props.canclePlan();
            }}
          >
            Abo kündigen
          </button>
        </div>
      </div>
    );
  }
}

export default Plans;
