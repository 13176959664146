import { LOGGED_IN, LOGOUT } from './actionCreators';

const intialState = {
  isLoggetIn: false,
  token: null,
};

function login(state = intialState, action) {
  switch (action.type) {
    case LOGOUT:
      return { ...state, isLoggetIn: false, token: null };
    case LOGGED_IN:
      return { ...state, isLoggetIn: true, token: action.token };
    default:
      return state;
  }
}

export default login;
