import React, { PureComponent } from 'react';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  BarChart,
  Bar,
} from 'recharts';
import { connect } from 'react-redux';
import moment from 'moment';
import { DateTime } from 'luxon';

import rest from '../rest';
import ListItem from '../snippets/list';
import arrowDown from '../images/arrow-down.svg';
import arrowUp from '../images/arrow-up.svg';

class Stats extends PureComponent {
  static formatDownloadsPerDay(downloads) {
    if (!downloads || !downloads.data) return null;
    const unsortedData = downloads.data.map(obj => {
      const date = moment(
        `${obj._id.year}-${obj._id.month}-${obj._id.day}`,
        'YYYY-MM-DD'
      );
      return {
        ts: date.unix(),
        day: date.format('DD.MM.YYYY'),
        passes: obj.count,
      };
    });
    return unsortedData.sort((a, b) => a.ts - b.ts);
  }

  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(rest.actions.downloadsPerDay());
    dispatch(rest.actions.points());
    dispatch(rest.actions.downloads());
    dispatch(rest.actions.transactions());
  }

  async reverseTransaction(transaction) {
    const { dispatch, token } = this.props;

    await fetch('https://api.passpoints.de/api/transactions', {
      method: 'PUT',
      headers: {
        token: token,
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: transaction._id,
      }),
    });

    dispatch(rest.actions.transactions());
  }

  render() {
    return (
      <div className="wrapper">
        <h1>Statistiken</h1>
        <div className="grid gutter">
          <div className="grid-item x50">
            <div className="box">
              <h2>Verteilte Punkte gesamt</h2>
              <h5>
                {this.props.points && this.props.points.data}
                <br />
                <small>Punkte</small>
              </h5>
            </div>
          </div>
          <div className="grid-item x50">
            <div className="box">
              <h2>Verteilte Pässe gesamt</h2>
              <h5>
                {this.props.downloads && this.props.downloads.data}/1000
                <br />
                <small>Pässe</small>
              </h5>
            </div>
          </div>
          <div className="grid-item x100">
            <div className="box">
              <h2>Verteilte Pässe</h2>
              <div className="chart-box">
                <ResponsiveContainer>
                  <BarChart
                    data={Stats.formatDownloadsPerDay(
                      this.props.downloadsPerDay
                    )}
                    maxBarSize={10}
                  >
                    <XAxis type="category" dataKey="day" />
                    <YAxis dataKey="passes" type="number" />
                    <CartesianGrid horizontal={false} />
                    <Tooltip />
                    <Bar dataKey="passes" fill="#2895cc" maxBarSize={40} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="grid-item x100">
            <div className="box">
              <h2>Transaktionen</h2>
              {this.props.transactions &&
                this.props.transactions.data &&
                this.props.transactions.data.map(transaction => {
                  const title =
                    transaction.data.type === 'added'
                      ? 'Hinzugefügt'
                      : 'Eingelöst';
                  return (
                    <ListItem
                      key={transaction._id}
                      title={
                        transaction.data.points +
                        ' Punkte ' +
                        (transaction.data.employee
                          ? '- von ' + transaction.data.employee.username
                          : '')
                      }
                      subtitle={
                        title +
                        ' am ' +
                        DateTime.fromISO(transaction.timestamp).toLocaleString(
                          DateTime.DATETIME_SHORT
                        )
                      }
                      showButton={!transaction.data.reversed}
                      buttonTitle="Rückgängig"
                      onButtonClick={() => {
                        this.reverseTransaction(transaction);
                      }}
                      image={
                        transaction.data.type === 'added' ? (
                          <img src={arrowUp} alt="" className="list-icon" />
                        ) : (
                          <img src={arrowDown} className="list-icon" alt="" />
                        )
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function select(state) {
  return {
    downloadsPerDay: state.downloadsPerDay,
    points: state.points,
    downloads: state.downloads,
    transactions: state.transactions,
    token: state.login.token,
  };
}

export default connect(select)(Stats);
