import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Alert from 'react-s-alert';
import { connect } from 'react-redux';
import { login } from './actionCreators';

class Login extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      password: '',
    };

    this.login = this.login.bind(this);
  }

  login(e) {
    e.preventDefault();

    const { email, password } = this.state;

    fetch('https://api.passpoints.de/api/companies/auth', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then(res => {
        if (res.status === 403) {
          Alert.error('E-Mail oder Passwort wurden falsch eingegeben.', {
            position: 'top',
            effect: 'stackslide',
            timeout: 2000,
          });

          throw Error('Wrong Credentials');
        }
        return res.json();
      })
      .then(json => {
        this.props.login(json.company.authToken);
        setTimeout(() => {
          this.props.history.push('/pass');
        }, 0);
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="login-box">
        <div className="hero-bg-one" />
        <div className="hero-bg-two" />
        <div className="wrapper">
          <div className="grid vertical-center">
            <div className="grid-item overflow center x100">
              <div className="box login left animate-in">
                <h1>Login</h1>
                <form onSubmit={this.login}>
                  <fieldset>
                    <h4>E-Mail</h4>
                    <input
                      placeholder={'E-Mail'}
                      type={'email'}
                      value={this.state.email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </fieldset>
                  <fieldset>
                    <h4>Passwort</h4>
                    <input
                      placeholder={'Passwort'}
                      type={'password'}
                      value={this.state.password}
                      onChange={e =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </fieldset>
                  <input className="big" type="submit" value="Login" />
                  <Link to={'/forgot'} className="login-link">
                    Passwort vergessen
                  </Link>
                  <Link to={'/register'} className="login-link">
                    Register
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  login: token => {
    dispatch(login(token));
  },
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Login)
);
