import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';

function Forgot() {
  const [email, setEmail] = useState('');

  const forgotPassword = async e => {
    e.preventDefault();

    const response = await fetch(
      'https://api.passpoints.de/api/companies/sendPwResetEmail',
      {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email }),
      }
    );
    if (response.status < 299) {
      Alert.success(
        'Wir haben ihnen eine E-Mail zum zurücksetzen Ihres Passworts gesendet.',
        {
          position: 'top',
          effect: 'stackslide',
          timeout: 2000,
        }
      );
    } else {
      Alert.error('Unter dieser E-Mail Addresse liegt kein Kundenaccount vor', {
        position: 'top',
        effect: 'stackslide',
        timeout: 2000,
      });
    }
  };

  return (
    <div className="login-box">
      <div className="hero-bg-one" />
      <div className="hero-bg-two" />
      <div className="wrapper">
        <div className="grid vertical-center">
          <div className="grid-item overflow center x100">
            <div className="box login left animate-in">
              <h1>Passwort vergessen</h1>
              <form onSubmit={forgotPassword}>
                <fieldset>
                  <h4>E-Mail</h4>
                  <input
                    placeholder={'E-Mail'}
                    type={'email'}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </fieldset>
                <input className="big" type="submit" value="Absenden" />
                <Link to={'/login'} className="login-link">
                  Login
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
