import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';
import moment from 'moment';
import Loading from '../../snippets/loading';

class Push extends Component {
  state = {};

  sendPush = e => {
    e.preventDefault();

    fetch('https://api.passpoints.de/api/companies/masspush', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: this.props.token,
      },
      body: JSON.stringify({
        title: this.props.state.pushTitle,
        message: this.props.state.pushMessage,
        expire: moment()
          .add(1, 'd')
          .toDate(),
      }),
    }).then(res => {
      if (res.status < 299) {
        Alert.success('Push-Nachricht gesendet', {
          position: 'top',
          effect: 'stackslide',
          timeout: 2000,
        });
      } else {
        Alert.error('Fehler beim senden der Push-Nachricht', {
          position: 'top',
          effect: 'stackslide',
          timeout: 2000,
        });
      }
    });
  };

  handleChangeTitle(evt) {
    this.props.changeState({
      pushTitle: evt.target.value,
    });
  }

  handleChangeMessage(evt) {
    this.props.changeState({
      pushMessage: evt.target.value,
    });
  }

  render() {
    const { company, downloads } = this.props;

    console.log(this.props);

    if (company.loading) {
      return <Loading loading overlay />;
    }

    const validPush =
      this.props.state.pushTitle &&
      this.props.state.pushTitle.length > 1 &&
      this.props.state.pushMessage &&
      this.props.state.pushMessage.length > 1;

    return (
      <form>
        <fieldset>
          <div className="info-box">
            <p>
              Benachrichtigen Sie Ihre Kunden über Events, Aktionen und Rabatte.
              Mit einem Klick auf Senden erhalten diese eine Push
              Benachrichtigung auf ihr Smartphone.
            </p>
          </div>
          <h3>Titel</h3>
          <input
            placeholder={'Titel'}
            onFocus={() => {
              this.props.changeState({ front: true });
            }}
            onChange={this.handleChangeTitle.bind(this)}
          />
        </fieldset>
        <fieldset>
          <h3>Nachricht</h3>
          <textarea
            placeholder={'Nachricht'}
            onChange={this.handleChangeMessage.bind(this)}
            onFocus={() => {
              this.props.changeState({ front: false });
            }}
          />
        </fieldset>
        <button
          type="button"
          disabled={!validPush}
          className="big"
          onClick={e => {
            this.sendPush(e);
          }}
        >
          An {downloads.data} Kunden senden
        </button>
        <span
          style={{ marginLeft: 15, fontSize: 14, color: 'rgb(150,150,150)' }}
        >
          Noch{' '}
          {company.data.pushesSend
            ? company.data.pushes - company.data.pushesSend
            : company.data.pushes}
          /{company.data.pushes} Pushes vorhanden
        </span>
      </form>
    );
  }
}

function select(state) {
  return {
    company: state.company,
    token: state.login.token,
    downloads: state.downloads,
  };
}

export default connect(select)(Push);
