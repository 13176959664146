import React, { Component } from 'react';
import Alert from 'react-s-alert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';

import Invoice from './settings/invoice';
import Mail from './settings/mail';
import Plans from '../snippets/plans';
import Employees from './settings/employees';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: 'small',
      save: false,
      tab: 0,
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(index, last) {
    console.log(`Selected tab: ${index}, Last tab: ${last}`);
    this.setState({ tab: index });

    return true;
  }

  changeState(state) {
    this.setState(state);
    this.setState({ save: true });
  }

  changePlan(plan) {
    if (plan === this.props.company.data.plan) return;
    if (window.confirm(`Bitte bestätigen Sie das wechslen zu Plan ${plan}`)) {
      this.setState({ plan });
      Alert.success('Plan erfolgreich gewechselt', {
        position: 'top',
        effect: 'stackslide',
        timeout: 2000,
      });
    }
  }

  canclePlan() {
    if (window.confirm('Sind Sie sicher das Sie Ihr Abo kündigen wollen? ')) {
      this.setState({ plan: 'none' });
      Alert.success(
        'Plan erfolgreich gekündigt, Ihr Account bleibt 30 Tage erhalten und wird dann endgültig gelöscht.',
        {
          position: 'top',
          effect: 'stackslide',
          timeout: 4000,
        }
      );
    }
  }

  render() {
    if (!this.props.company.data) {
      return null;
    }

    return (
      <div className="wrapper">
        <h1>Einstellungen</h1>
        <div className="grid gutter">
          <div className="grid-item x60">
            <div className="box">
              <Tabs
                onSelect={this.handleSelect}
                selectedIndex={this.state.tab}
                className={`settings tab-${this.state.tab}`}
              >
                <TabList>
                  <Tab>Rechnungsdaten</Tab>
                  <Tab>E-Mail</Tab>
                  <Tab>Mitarbeiter</Tab>
                  <div className="ReactTabs__Line" />
                </TabList>
                <TabPanel>
                  <Invoice />
                </TabPanel>
                <TabPanel>
                  <Mail />
                </TabPanel>
                <TabPanel>
                  <Employees />
                </TabPanel>
              </Tabs>
            </div>
          </div>
          <div className="grid-item x40">
            <div className="box">
              <h3>Ihr Plan</h3>
              <Plans
                plan={this.props.company.data.plan}
                changePlan={this.changePlan.bind(this)}
                canclePlan={this.canclePlan.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function select(state) {
  return { company: state.company };
}

export default connect(select)(Settings);
