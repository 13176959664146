import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from '../images/passpoints-logo-new.svg';
import { logout } from '../views/login/actionCreators';

class Header extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    location: PropTypes.shape({}).isRequired,
    isLoggetIn: PropTypes.bool.isRequired,
  };

  render() {
    const { location } = this.props;

    if (location.pathname.indexOf('/pass/') !== -1) {
      return null;
    }

    console.log(this.props.isLoggetIn);

    const navList = this.props.isLoggetIn ? (
      <ul>
        <li>
          <NavLink to={'/stats'} activeClassName="active">
            Statistiken
          </NavLink>
        </li>
        <li>
          <NavLink to={'/pass'} activeClassName="active">
            Pass
          </NavLink>
        </li>
        <li>
          <NavLink to={'/push'} activeClassName="active">
            Push
          </NavLink>
        </li>
        <li>
          <NavLink to={'/settings'} activeClassName="active">
            Einstellungen
          </NavLink>
        </li>
        <li>
          <button
            onClick={() => {
              this.props.logout();
              this.props.history.push('/login');
            }}
          >
            Logout
          </button>
        </li>
      </ul>
    ) : (
      <ul>
        <li>
          <NavLink to={'/register'} activeClassName="active">
            Register
          </NavLink>
        </li>
        <li>
          <NavLink to={'/login'} activeClassName="active">
            Login
          </NavLink>
        </li>
      </ul>
    );

    return (
      <header>
        <div className="wrapper">
          <div className="grid">
            <div className="grid-item left x25">
              <NavLink to={'/'}>
                <img src={logo} className="logo" alt="Passpoints" />
              </NavLink>
            </div>
            <div className="grid-item right">
              <nav>{navList}</nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  isLoggetIn: state.login.isLoggetIn,
});

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(logout());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
