import React, { Component } from 'react';
import MDSpinner from "react-md-spinner";

class Loading extends Component {
  render() {
    const { loading, overlay } = this.props;
    return (
      <div className={'loading ' + (loading ? 'true' : 'false') + ' '  + (overlay ? 'overlay' : '')}>
        <MDSpinner size={50} />
      </div>
    );
  }
}

export default Loading;

