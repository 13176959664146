import React, { Component } from 'react';

class ListItem extends Component {
  render() {
    const {
      title,
      subtitle,
      image,
      onButtonClick,
      buttonTitle,
      buttonDataId,
      showButton,
    } = this.props;

    return (
      <div className="list-item">
        <div className={image ? 'list-content image' : 'list-content'}>
          {image ? <div className="list-media">{image}</div> : null}
          <p>
            {title}
            <br />
            {subtitle ? <small>{subtitle}</small> : null}
          </p>
        </div>
        {showButton && (
          <button
            className="list-button"
            onClick={onButtonClick}
            data-id={buttonDataId}
          >
            {buttonTitle}
          </button>
        )}
      </div>
    );
  }
}

export default ListItem;
