import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import Dropzone from 'react-dropzone';
import Switch from 'react-toggle-switch';
import '../../../node_modules/react-toggle-switch/dist/css/switch.min.css';

class Design extends Component {
  static propTypes = {
    changeState: PropTypes.func.isRequired,
    state: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);

    this.onDropLogo = this.onDropLogo.bind(this);
    this.onDropCover = this.onDropCover.bind(this);
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.resetLogo = this.resetLogo.bind(this);
    this.resetCover = this.resetCover.bind(this);
    this.handleChangeTextColor = this.handleChangeTextColor.bind(this);
  }

  onDropLogo(files) {
    if (files) {
      this.props.changeState({
        logo: { file: files[0], preview: URL.createObjectURL(files[0]) },
      });
    }
  }

  onDropCover(files) {
    if (files) {
      this.props.changeState({
        cover: { file: files[0], preview: URL.createObjectURL(files[0]) },
      });
    }
  }

  resetLogo(e) {
    e.preventDefault();
    if (this.props.state.logo) {
      URL.revokeObjectURL(this.props.state.logo.preview);
      this.props.changeState({ logo: null });
    }
  }

  resetCover(e) {
    e.preventDefault();
    if (this.props.state.cover) {
      URL.revokeObjectURL(this.props.state.cover.preview);
      this.props.changeState({ cover: null });
    }
  }

  handleChangeColor(color) {
    this.props.changeState({
      company: {
        ...this.props.state.company,
        bonuspass: {
          ...this.props.state.company.bonuspass,
          backgroundColor: color.hex,
        },
      },
    });
  }

  handleChangeTextColor(color) {
    this.props.changeState({
      company: {
        ...this.props.state.company,
        bonuspass: {
          ...this.props.state.company.bonuspass,
          foregroundColor: color.hex,
        },
      },
    });
  }

  handleChangeTitle(evt) {
    this.props.changeState({
      company: {
        ...this.props.state.company,
        name: evt.target.value,
      },
    });
  }

  render() {
    const { state } = this.props;
    return (
      <form>
        <fieldset>
          <h3>Passname</h3>
          <input value={state.company.name} onChange={this.handleChangeTitle} />
          <div className="switch-input">
            <label>Passnamen anzeigen?</label>
            <Switch
              on={state.company.showName}
              className="pp-switch"
              circleStyles={{ onColor: '#2895cc', diameter: 20 }}
              switchStyles={{ width: 40 }}
              onClick={() => {
                this.props.changeState({
                  company: {
                    ...state.company,
                    showName: !state.company.showName,
                  },
                });
              }}
            />
          </div>
        </fieldset>
        <fieldset>
          <h3>Hintergrundfarbe</h3>
          <ChromePicker
            disableAlpha
            color={state.company.bonuspass.backgroundColor}
            onChangeComplete={this.handleChangeColor}
          />
          <h3>Textfarbe</h3>
          <ChromePicker
            disableAlpha
            color={state.company.bonuspass.foregroundColor}
            onChangeComplete={this.handleChangeTextColor}
          />
        </fieldset>
        <fieldset>
          <h3>Logo</h3>
          <div className={state.logo ? 'dropzone cover' : 'dropzone'}>
            <Dropzone
              accept="image/png, image/jpeg"
              onDrop={this.onDropLogo}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <p>
                    Ziehe Sie eine Datei von deinem Computer hier hin <br />
                    oder wählen Sie per klick eine Datei aus.
                  </p>
                  <img src={state.logo && state.logo.preview} alt="logo" />{' '}
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
          <button style={{ marginTop: 10 }} onClick={this.resetLogo}>
            Zurücksetzen
          </button>
        </fieldset>
        <fieldset>
          <h3>Banner</h3>
          <div className={state.cover ? 'dropzone cover' : 'dropzone'}>
            <Dropzone
              accept="image/png, image/jpeg"
              onDrop={this.onDropCover}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <p>
                    Ziehe Sie eine Datei von deinem Computer hier hin <br />
                    oder wählen Sie per klick eine Datei aus.
                  </p>
                  <img src={state.cover && state.cover.preview} alt="cover" />
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
          <button style={{ marginTop: 10 }} onClick={this.resetCover}>
            Zurücksetzen
          </button>
        </fieldset>
      </form>
    );
  }
}

export default Design;
