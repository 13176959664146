import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, Wrapper } from '../../snippets/map';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const token =
  window.location.origin === 'http://localhost:3000'
    ? 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkY1UzdXWk01NFoifQ.eyJpc3MiOiI2QlM1OVNDOTQyIiwiaWF0IjoxNTM5ODkzMzM4LjI3NCwiZXhwIjoxNTcxNDI5MzM4LjI3NCwib3JpZ2luIjoiaHR0cDovL2xvY2FsaG9zdDozMDAwIn0.tNfgcZD4e3Qh9AzrwVrQery5LlMeScxeis2AcA1qvlWzuqqu4WiGfSPXIi-GCh2s3E8GD541rZ_9MrpahAa0JQ'
    : 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkY1UzdXWk01NFoifQ.eyJpc3MiOiI2QlM1OVNDOTQyIiwiaWF0IjoxNTM5ODkzMTI1LjY0NSwiZXhwIjoxNTcxNDI5MTI1LjY0NSwib3JpZ2luIjoiaHR0cHM6Ly9hcHAucGFzc3BvaW50cy5kZSJ9.65viY1RxfI-AiRkdawIUZbp5mylwsoxo290cNz8dkjIsPMwM38DdwSq9GeJ5cAwWBb9q23u-UzfzEBhPOIpWjg';

class Back extends Component {
  static propTypes = {
    state: PropTypes.object,
    changeState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      address: '',
    };

    this.handleChangeInformations = this.handleChangeInformations.bind(this);
    this.onChange = address => this.setState({ address });
    // props.state.company.locations;
  }

  handleChangeInformations(evt) {
    this.props.changeState({
      company: {
        ...this.props.state.company,
        bonuspass: {
          ...this.props.state.company.bonuspass,
          backfield: evt.target.value,
        },
      },
    });
  }

  render() {
    const { state } = this.props;

    const options = {
      types: ['address'],
    };

    return (
      <form>
        <fieldset>
          <div className="info-box">
            <p>
              Hier können sie die Informationen einstellen welche auf der
              Rückseite ihres Passes angezeigt werden. Zudem können sie einen
              Standort angeben um Kunden in der Nähe zu benachrichtigen!
            </p>
          </div>
          <h3>Informationen auf Rückseite</h3>
          <textarea
            rows={10}
            value={state.company.bonuspass.backfield}
            onChange={this.handleChangeInformations}
          />
        </fieldset>
        <fieldset>
          <h3>Standort</h3>
          <PlacesAutocomplete
            value={this.state.address}
            onChange={address => {
              this.setState({ address });
            }}
            onSelect={address => {
              geocodeByAddress(address).then(results => {
                getLatLng(results[0]).then(loc => {
                  this.props.changeState({
                    company: {
                      ...this.props.state.company,
                      locations: [
                        {
                          longitude: loc.lng,
                          latitude: loc.lat,
                        },
                      ],
                    },
                  });
                });
              });
            }}
            searchOptions={options}
            debounce={500}
            shouldFetchSuggestions={this.state.address.length > 3}
          >
            {({ getInputProps, getSuggestionItemProps, suggestions }) => (
              <div className="autocomplete-root">
                <input {...getInputProps()} />
                <div className="autocomplete-dropdown-container">
                  {suggestions.map(suggestion => (
                    <div {...getSuggestionItemProps(suggestion)}>
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {state.company.locations.length ? (
            <Wrapper
              className="map-wrapper"
              authorizationCallback={done => done(token)}
            >
              <Map
                annotations={[
                  {
                    coordinate: state.company.locations[0],
                    title: state.company.name,
                    color: '#2895cc',
                  },
                ]}
                tintColor="#2895cc"
                center={state.company.locations[0]}
                defaultZoom={9}
              />
            </Wrapper>
          ) : null}
        </fieldset>
      </form>
    );
  }
}

export default Back;
