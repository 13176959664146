import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';

function Forgot() {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const resetPassword = async e => {
    e.preventDefault();

    if (password !== password2) {
      Alert.error('Die Passwörter stimmen nicht überein', {
        position: 'top',
        effect: 'stackslide',
        timeout: 2000,
      });
      return;
    }

    const response = await fetch(
      'https://api.passpoints.de/api/companies/editPassword',
      {
        method: 'PUT',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          token: new URLSearchParams(window.location.search).get('t'),
        },
        body: JSON.stringify({ newPassword: password }),
      }
    );
    if (response.status < 299) {
      Alert.success('Ihr Passwort würde erfolgreich aktualisiert.', {
        position: 'top',
        effect: 'stackslide',
        timeout: 2000,
      });
    } else {
      Alert.error(
        'Es ist ein Problem beim Zurücksetzen ihres Passworts aufgetretten',
        {
          position: 'top',
          effect: 'stackslide',
          timeout: 2000,
        }
      );
    }
  };

  return (
    <div className="login-box">
      <div className="hero-bg-one" />
      <div className="hero-bg-two" />
      <div className="wrapper">
        <div className="grid vertical-center">
          <div className="grid-item overflow center x100">
            <div className="box login left animate-in">
              <h1>Passwort zurücksetzen</h1>
              <form onSubmit={resetPassword}>
                <fieldset>
                  <h4>Passwort</h4>
                  <input
                    id="password"
                    placeholder={'Passwort'}
                    type={'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </fieldset>
                <fieldset>
                  <h4>Passwort wiederholen</h4>
                  <input
                    id="passwordVerfiyField"
                    placeholder={'Passwort'}
                    type={'password'}
                    value={password2}
                    onChange={e => setPassword2(e.target.value)}
                  />
                </fieldset>
                <input className="big" type="submit" value="Passwort ändern" />
                <Link to={'/login'} className="login-link">
                  Login
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
