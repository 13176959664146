import React, { Component } from 'react';
import { connect } from 'react-redux';
import rest from '../rest';
import phone from '../images/iphone.png';
import screen from '../images/screen_push.png';
import icon from '../images/passpoints-app.png';
import Loading from '../snippets/loading';
import Push from './pass/push';

class PushMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pushTitle: undefined,
      pushMessage: undefined,
    };

    this.changeState = this.changeState.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(rest.actions.downloads());
    if (!this.props.company.data) dispatch(rest.actions.company({ id: 'me' }));
  }

  changeState(state) {
    this.setState(state);
  }

  render() {
    const { company } = this.props;

    if (!this.props.company.data) {
      return null;
    }

    if (company.loading) {
      return <Loading loading overlay />;
    }

    return (
      <div className="wrapper">
        <Loading loading={this.state.loading} overlay />
        <h1>Push Nachricht</h1>
        <div className="grid gutter">
          <div className="grid-item x40">
            <div className="box">
              <div className="phone-box">
                <img src={phone} alt="Pass Designer" className="phone" />
                <img src={screen} alt="Wallet App" className="screen" />
                <div className="push">
                  <div className="push-header">
                    <img src={icon} alt="Wallet Push" className="push-icon" />
                    {company.data.name}
                    <span>{'Jetzt'}</span>
                  </div>
                  <div className="push-content">
                    <span>
                      {this.state.pushTitle || 'Hier steht Ihr Titel.'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-item x60">
            <div className="box">
              <Push state={this.state} changeState={this.changeState} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function select(state) {
  return { company: state.company };
}

export default connect(select)(PushMessage);
