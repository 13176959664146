import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { render } from 'react-dom';
import App from './App';

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { Provider, connect } from 'react-redux';
import rest from './rest';
import reducers from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import storage from 'redux-persist/lib/storage';

import Stats from './views/stats';
import PassDesigner from './views/pass';
import Push from './views/push';
import Settings from './views/settings';
import Login from './views/login';
import Register from './views/register';
import Themes from './views/themes';
import PassLanding from './views/landing';
import Forgot from './views/forgot';
import ResetPassword from './views/reset';

import './css/font-awesome.min.css';
import './index.css';

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const reducer = combineReducers({ ...reducers, ...rest.reducers });
const persistConfig = {
  key: 'root',
  storage: storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStoreWithMiddleware(persistedReducer);
const persistor = persistStore(store);

const PrivateRoute = connect(state => ({
  isAuthenticated: state.login.isLoggetIn,
}))(({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      )
    }
  />
));

// Declarative route configuration (could also load this config lazily
// instead, all you really need is a single root route, you don't need to
// colocate the entire config).
render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App>
            <Switch>
              <Route path="/pass/:name" component={PassLanding} />
              <PrivateRoute path="/stats" component={Stats} />
              <PrivateRoute path="/pass" component={PassDesigner} />
              <PrivateRoute path="/push" component={Push} />
              <PrivateRoute path="/themes" component={Themes} />
              <PrivateRoute path="/settings" component={Settings} />
              <Route path="/login" component={Login} />
              <Route path="/forgot" component={Forgot} />
              <Route path="/passwordreset" component={ResetPassword} />
              <Route path="/register/:plan" component={Register} />
              <Route path="/register" component={Register} />
              <Redirect from="/" to="/stats" />
            </Switch>
          </App>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
