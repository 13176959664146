import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import Switch from 'react-toggle-switch';
import StripeCheckout from 'react-stripe-checkout';
import Plans from '../snippets/plans';
import { Form, Text } from 'react-form';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plan: props.params && props.params.plan ? props.params.plan : 'pp_small',
      agreesWithTermsOfSerive: false,
    };

    this.changePlan = this.changePlan.bind(this);
    this.register = this.register.bind(this);
    this.validate = this.validate.bind(this);
  }

  onToken(token) {
    console.log(token);
  }

  register(e) {
    e.preventDefault();
    Alert.error('Error', {
      position: 'top',
      effect: 'stackslide',
      timeout: 2000,
    });
  }

  changePlan(plan) {
    this.setState({ plan });
  }

  validate(values) {
    const { email, password, password2 } = values;
    return {
      email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(email)
        ? null
        : 'Invalide Emailadresse',
      password: /^[A-Z0-9.:%+-]{5}/i.test(password)
        ? null
        : 'Ihr Password muss mehr als 5 Zeichen haben',
      password2:
        password === password2 ? null : 'Passwörter stimmen nicht über ein',
    };
  }

  render() {
    const { agreesWithTermsOfSerive } = this.state;

    return (
      <div className="login-box">
        <div className="hero-bg-one" />
        <div className="hero-bg-two" />
        <div className="wrapper">
          <div className="grid vertical-center">
            <div className="grid-item overflow center x100">
              <div className="box login register left animate-in">
                <div className="grid gutter">
                  <h1>Registrieren</h1>
                  <div className="info-box">
                    <p>
                      Online Registrieren ist momentan deaktiviert. Sollten Sie
                      passpoints nutzen wollen kontakieren Sie uns bitte direkt.{' '}
                      <a href="mailto:support@passpoints.de">
                        support@passpoints.de
                      </a>
                    </p>
                  </div>
                  <div
                    className="grid-item left x50"
                    style={{ paddingRight: '25px' }}
                  >
                    <h3>Ihre Daten</h3>
                    <Form onSubmit={this.register} validate={this.validate}>
                      {({ submitForm, errors }) => {
                        console.log(errors);
                        return (
                          <div>
                            <fieldset
                              className={errors && errors.email && '-hasError'}
                              title="test"
                            >
                              <h4>E-Mail</h4>
                              <Text
                                field="email"
                                type="email"
                                placeholder={'E-Mail'}
                              />
                            </fieldset>
                            <fieldset
                              className={
                                errors && errors.password && '-hasError'
                              }
                            >
                              <h4>Passwort</h4>
                              <Text
                                field="password"
                                type="password"
                                placeholder={'Passwort'}
                              />
                            </fieldset>
                            <fieldset
                              className={
                                errors && errors.password2 && '-hasError'
                              }
                            >
                              <Text
                                field="password2"
                                type="password"
                                placeholder={'Passwort wiederholen'}
                              />
                            </fieldset>
                            <fieldset>
                              <div className="switch-input">
                                <label>
                                  Ich akzeptiere die{' '}
                                  <a
                                    href="https://passpoints.de/rights"
                                    target="_balnk"
                                  >
                                    AGB
                                  </a>
                                </label>
                                <Switch
                                  on={agreesWithTermsOfSerive}
                                  onClick={() =>
                                    this.setState({
                                      agreesWithTermsOfSerive: !agreesWithTermsOfSerive,
                                    })
                                  }
                                  className="pp-switch"
                                />
                              </div>
                            </fieldset>
                            <button
                              disabled={!!errors}
                              className="big"
                              type="submit"
                            >
                              Registrieren
                            </button>
                            <Link to={'/login'} className="login-link">
                              Login
                            </Link>
                          </div>
                        );
                      }}
                    </Form>
                    {/* <StripeCheckout
                      token={this.onToken}
                      name="passpoints"
                      description="jo"
                      image="https://s3-eu-west-1.amazonaws.com/passpoints-webpage/dev/img/passpoints-logo-blue.png"
                      panelLabel="Give Money"
                      amount={1000000}
                      currency="EUR"
                      email="test@dfsdfdsf.de"
                      stripeKey="pk_test_48kuB1lwQ3ND3ZyNQqI6TcBu"
                    /> */}
                  </div>
                  <div
                    className="grid-item overflow x50"
                    style={{ paddingLeft: '25px' }}
                  >
                    <h3>Plan wählen</h3>
                    <Plans
                      plan={this.state.plan}
                      register
                      changePlan={this.changePlan}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
