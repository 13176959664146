import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loading from '../../snippets/loading';
import rest from '../../rest';
import ListItem from '../../snippets/list';

class Employees extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    employees: PropTypes.shape({
      data: PropTypes.array,
    }).isRequired,
  };

  constructor() {
    super();

    this.deleteEmployee = this.deleteEmployee.bind(this);
  }

  state = {
    newName: '',
    newPassword: '',
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(rest.actions.employees());
  }

  createEmployee = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(
      rest.actions.employees.post(
        {},
        {
          body: JSON.stringify({
            employee: {
              username: this.state.newName,
              password: this.state.newPassword,
            },
          }),
        }
      )
    );

    this.setState({
      newName: '',
      newPassword: '',
    });
  };

  deleteEmployee(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(rest.actions.employees.delete({ id: e.target.dataset.id }));
    console.log(e.target.dataset.id);
  }

  render() {
    const { employees, company } = this.props;

    if (employees.loading || !employees.data) {
      return <Loading loading />;
    }

    return (
      <form>
        <div className="grid gutter form-grid">
          <div className="grid-item x100">
            <div className="info-box">
              <p>
                Hier erstellte Mitarbeiter Accounts können auf den Scann Apps
                genutzt werden um Punkte zu pässen hinzuzufügen. In den
                Statistiken sehen Sie dann wann welcher Account genutzt wurde.
              </p>
            </div>
            <fieldset>
              <h3>Mitarbeiter</h3>
              {employees.data.map(employee => (
                <ListItem
                  title={`${company.data.shortname}-${employee.username}`}
                  subtitle={'Passwort: ' + employee.password}
                  buttonTitle={'Löschen'}
                  buttonDataId={employee._id}
                  onButtonClick={this.deleteEmployee}
                  key={employee._id}
                />
              ))}
            </fieldset>
            <fieldset>
              <h3 style={{ marginTop: 20 }}>Mitarbeiter hinzufügen</h3>
              <div className="grid gutter form-grid">
                <div className="grid-item x50">
                  <fieldset>
                    <h3>Name</h3>
                    <input
                      value={this.state.newName}
                      onChange={event =>
                        this.setState({ newName: event.target.value })
                      }
                      placeholder={'Name'}
                    />
                  </fieldset>
                </div>
                <div className="grid-item x50">
                  <fieldset>
                    <h3>Passwort</h3>
                    <input
                      type="password"
                      value={this.state.newPassword}
                      onChange={event =>
                        this.setState({ newPassword: event.target.value })
                      }
                      placeholder={'Passwort'}
                    />
                  </fieldset>
                </div>
                <button
                  type="button"
                  onClick={this.createEmployee}
                  style={{ marginTop: 10 }}
                >
                  Hinzufügen
                </button>
              </div>
            </fieldset>
          </div>
        </div>
      </form>
    );
  }
}

function select(state) {
  return { company: state.company, employees: state.employees };
}

export default connect(select)(Employees);
