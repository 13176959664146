import React, { Component } from 'react';
import Alert from 'react-s-alert';
import badge from '../images/add-to-apple-wallet-logo.png';
import logo from '../images/passpoints-logo-pwrdby.svg';

const QRCode = require('qrcode.react');

class PassLanding extends Component {
  static sendPass(e) {
    e.preventDefault();
    Alert.error('Error', {
      position: 'top',
      effect: 'stackslide',
      timeout: 2000,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      background: '#2895cc',
      qr: 'https://passpoints.de',
    };
  }

  render() {
    return (
      <div className="landing-wrapper">
        <div
          className="landing-bg"
          style={{
            backgroundImage: `url(http://res.cloudinary.com/da0jhbase/image/upload/b_rgb:${this.state.background.substr(
              1
            )},c_fill,e_colorize:80,h_1024,o_100,w_1240/v1477920316/cover_lbibks.jpg)`,
          }}
        >
          <div className="vertical-center-box center">
            <div>
              <h1>Unsere digitale Kundenkarte</h1>
              <h2>Schnell und einfach auf Ihrem Smartphone</h2>
            </div>
          </div>
          <div className="landing-block" />
        </div>
        <div className="landing-qr animate-in">
          <QRCode value={this.state.qr} />
          <img src={badge} className="landing-badge" alt="Passpoints" />
        </div>
        <div className="wrapper center">
          <p>
            Benutzen Sie einfach die Wallet App auf Ihrem iPhone oder laden Sie
            die WallatPasses App auf Ihrem Android Smartphone und scannen Sie
            den QR-Code.
          </p>
          <div className="social-button-wrapper">
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A//passpoints.de"
              className="social-button facebook"
            >
              <i className="fa fa-facebook" />
            </a>
            <a
              href="https://twitter.com/home?status=https%3A//passpoints.de"
              className="social-button twitter"
            >
              <i className="fa fa-twitter" />
            </a>
            <a
              href="https://plus.google.com/share?url=https%3A//passpoints.de"
              className="social-button google"
            >
              <i className="fa fa-google" />
            </a>
            <a
              href="https://pinterest.com/pin/create/button/?url=https%3A//passpoints.de&media=&description="
              className="social-button pinterest"
            >
              <i className="fa fa-pinterest" />
            </a>
          </div>
          <div className="landing-overlay">
            <form>
              <fieldset>
                <input placeholder={'E-Mail'} />
              </fieldset>
              <button onClick={e => this.sendPass(e)}>Pass Senden</button>
            </form>
          </div>
        </div>
        <a
          href="https://www.passpoints.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} className="logo" alt="Passpoints" />
        </a>
      </div>
    );
  }
}

export default PassLanding;
