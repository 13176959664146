import React, { Component } from 'react';
import default_logo from '../images/passpoints-logo-white.svg';
var QRCode = require('qrcode.react');

class Pass extends Component {
  render() {
    const {
      manual,
      front,
      background,
      title,
      cover,
      color,
      logo,
      showTitle,
      informations,
      qr,
      pushTitle,
      pushMessage,
    } = this.props;
    return (
      <div
        className={
          'pass-box ' +
          (manual ? 'manual-flip' : '') +
          ' ' +
          (front ? '' : 'flip')
        }
      >
        <div className="pass">
          <div className="front" style={{ backgroundColor: background }}>
            <div className="content">
              <div className="title" style={{ color: color }}>
                <img
                  style={{ maxWidth: 100, objectFit: 'contain' }}
                  src={logo ? logo : default_logo}
                  alt="{title}"
                />
                {showTitle && <span>{title}</span>}
              </div>
              <div
                className="cover"
                style={{ backgroundImage: 'url(' + cover + ')' }}
              />
              <div className="grid">
                <div className="grid-item left x25">
                  <div className="name">
                    <small style={{ color: color }}>Name</small>
                    <br />
                  </div>
                </div>
                <div className="grid-item right x75">
                  <div className="news">
                    <small style={{ color: color }}>News</small>
                    <br />
                    {pushTitle}
                  </div>
                </div>
              </div>
              <div className="qr-code">{qr && <QRCode value={qr} />}</div>
              <div className="gradient" />
            </div>
          </div>
          <div className="back" style={{ backgroundColor: background }}>
            <div className="content">
              <div
                className="done"
                onClick={() => {
                  this.props.changeState({ front: true });
                }}
              >
                Fertig
              </div>
              <div className="back-content">
                <div className="back-content-box">
                  <div className="back-title">News</div>
                  <p style={{ fontSize: '0.9em' }}>{pushMessage}</p>
                </div>
                <div className="back-content-box">
                  <div className="back-title">Informationen</div>
                  <p style={{ fontSize: '0.9em' }}>{informations}</p>
                </div>
              </div>
              <div className="gradient" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pass;
