import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Pass from '../snippets/pass';

const themes = [
  {
    id: 'Amerikanisch/Mexico',
    imagename: 'burger.png',
    color: 'rgb(220,125,40)',
  },
  {
    id: 'Asiatisch/Indisch',
    imagename: 'sushi.png',
    color: 'rgb(173,87,57)',
  },
  {
    id: 'Orientalisch',
    imagename: 'orient.png',
    color: 'rgb(219,44,44)',
  },
  {
    id: 'Mediterran',
    imagename: 'pizza.png',
    color: 'rgb(103,170,120)',
  },
  {
    id: 'Europäisch',
    imagename: 'bread.png',
    color: 'rgb(240,210,89)',
  },
  {
    id: 'Biergarten',
    imagename: 'beer.png',
    color: 'rgb(162,40,30)',
  },
  {
    id: 'Cocktailbar',
    imagename: 'bar.png',
    color: 'rgb(73,190,190)',
  },
  {
    id: 'Disco',
    imagename: 'disco.png',
    color: 'rgb(89,100,190)',
  },
  {
    id: 'Waschstraßen',
    imagename: 'carwash.png',
    color: 'rgb(139,150,170)',
  },
  {
    id: 'Autoverleih',
    imagename: 'rentacar.png',
    color: 'rgb(210,210,220)',
  },
  {
    id: 'Wekstätte',
    imagename: 'workshop.png',
    color: 'rgb(57,80,130)',
  },
  {
    id: 'Sonnenstudio',
    imagename: 'sunbed.png',
    color: 'rgb(246,190,60)',
  },
  {
    id: 'Nagelstudio',
    imagename: 'nails.png',
    color: 'rgb(212,25,45)',
  },
  {
    id: 'Friseur',
    imagename: 'hairdresser.png',
    color: 'rgb(216,190,160)',
  },
  {
    id: 'Wellness & Spa',
    imagename: 'wellness.png',
    color: 'rgb(195,100,50)',
  },
  {
    id: 'Kosmetik',
    imagename: 'cosmetics.png',
    color: 'rgb(212,120,150)',
  },
  {
    id: 'Juwelier',
    imagename: 'jewlery.png',
    color: 'rgb(141,170,190)',
  },
  {
    id: 'Gärtnerei/Blumen',
    imagename: 'flowers.png',
    color: 'rgb(97,135,50)',
  },
  {
    id: 'Kleidung',
    imagename: 'shopping.png',
    color: 'rgb(77,65,60)',
  },
  {
    id: 'Lebensmittel',
    imagename: 'fruits.png',
    color: 'rgb(243,120,50)',
  },
  {
    id: 'Fitnessstudio',
    imagename: 'fitness.png',
    color: 'rgb(235,70,50)',
  },
  {
    id: 'Soccerhalle',
    imagename: 'soccer.png',
    color: 'rgb(72,95,75)',
  },
  {
    id: 'Tennis',
    imagename: 'tennis.png',
    color: 'rgb(180,60,35)',
  },
  {
    id: 'Golf',
    imagename: 'golf.png',
    color: 'rgb(111,145,60)',
  },
  {
    id: 'Gokart',
    imagename: 'gocart.png',
    color: 'rgb(35,40,45)',
  },
];

function Themes(props) {
  const [redirect, setRedirect] = useState(null);

  const changePass = async data => {
    if (window.confirm('Wollen Sie Ihr Passdesign überschreiben?')) {
      const response = await fetch(
        'https://api.passpoints.de/api/companies/me',
        {
          method: 'PUT',
          headers: {
            token: props.token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            company: {
              bonuspass: {
                strip: `https://dyrcfk4vzja57.cloudfront.net/passimages/themes/${
                  data.imagename
                }`,
                backgroundColor: data.color,
                foregroundColor: 'rgb(255,255,255)',
              },
            },
          }),
        }
      );
      setRedirect(true);
    }
  };

  if (redirect) {
    return <Redirect to="/pass" />;
  }

  return (
    <div className="wrapper">
      <h1>Themes</h1>
      <div className="grid gutter">
        {themes.map(data => (
          <div
            className="grid-item animated-hover x20"
            onClick={() => {
              changePass(data);
            }}
          >
            <Pass
              manual
              front
              background={data.color}
              color={'#FFF'}
              title={data.id}
              showTitle
              cover={`https://dyrcfk4vzja57.cloudfront.net/passimages/themes/${
                data.imagename
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function select(state) {
  return {
    token: state.login.token,
  };
}

export default connect(select)(Themes);
