import React, { Component } from "react";
import { connect } from "react-redux";

import rest from "../../rest";
import ListItem from "../../snippets/list";

class Products extends Component {
  state = {
    newName: "",
    newPoints: 0
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(rest.actions.bonusProducts());
  }

  createProduct = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(
      rest.actions.bonusProducts.post(
        {},
        {
          body: JSON.stringify({
            bonusProduct: {
              name: this.state.newName,
              points: this.state.newPoints,
            },
          }),
        }
      )
    );
  };

  deleteProduct(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(rest.actions.bonusProducts.delete({ id: e.target.dataset.id }));
  }

  render() {
    if (!this.props.bonusProducts || !this.props.bonusProducts.data) {
      return null;
    }

    const products = this.props.bonusProducts.data;
    return (
      <form>
        <fieldset>
          <div className="info-box">
            <p>
              Hier können sie die Produkte angeben die ihre Kunden mit ihren
              Punkten einlösen können.
            </p>
          </div>
          <h3>
            Ihre Bonusprodukte ({products.length}
            /5)
          </h3>
          {products.map(product => (
            <ListItem
              key={product._id}
              title={product.name}
              subtitle={product.points + " Punkte"}
              buttonTitle={"Löschen"}
              buttonDataId={product._id}
              onButtonClick={this.deleteProduct}
            />
          ))}
        </fieldset>
        <fieldset>
          <h3>Bonusprodukte hinzufügen</h3>
          <div className="grid gutter form-grid">
            <div className="grid-item x50">
              <fieldset>
                <h3>Name</h3>
                <input
                  onChange={event =>
                    this.setState({ newName: event.target.value })
                  }
                  value={this.state.newName}
                  placeholder={"Name"}
                />
              </fieldset>
            </div>
            <div className="grid-item x50">
              <fieldset>
                <h3>Punkte</h3>
                <input
                  type="number"
                  onChange={event =>
                    this.setState({ newPoints: event.target.value })
                  }
                  value={this.state.newPoints}
                  placeholder={"Punkte"}
                />
              </fieldset>
            </div>
            <button onClick={this.createProduct} style={{ marginTop: 10 }}>Hinzufügen</button>
          </div>
        </fieldset>
      </form>
    );
  }
}

function select(state) {
  return {
    bonusProducts: state.bonusProducts
  };
}

export default connect(select)(Products);
