import React, { Component } from 'react';
import Alert from 'react-s-alert';
import Intercom from 'react-intercom';
import { withRouter } from 'react-router';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

import Header from './snippets/header';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
    };
  }

  render() {
    const { children, location } = this.props;
    return (
      <div className="App">
        <Header location={location} />
        <main>{children}</main>
        <Alert stack={{ limit: 3 }} />
        <Intercom appID="qcpxz9qf" />
      </div>
    );
  }
}

export default withRouter(App);
