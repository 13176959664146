import reduxApi from 'redux-api';
import adapterFetch from 'redux-api/lib/adapters/fetch';

const getOptions = (urlT, params, getState) => ({
  headers: {
    token: getState().login.token,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default reduxApi({
  company: {
    url: 'https://api.passpoints.de/api/companies/:id',
    options: getOptions,
    crud: true,
    transformer: function companyTransformer(data, prevData, action) {
      data || (data = {});
      return data.company;
    },
  },
  bonusProducts: {
    url: 'https://api.passpoints.de/api/bonusProducts',
    options: getOptions,
    crud: true,
    transformer: function companyTransformer(data, prevData, action) {
      data || (data = {});
      return data.bonusProducts;
    },
  },
  employees: {
    url: 'https://api.passpoints.de/api/employees/:id',
    options: getOptions,
    crud: true,
    transformer: function employeesTransformer(data, prevData, action) {
      if (
        action &&
        action.request.params &&
        action.request.params.method === 'POST'
      ) {
        return [...prevData, data.employee];
      }
      if (
        action &&
        action.request.params &&
        action.request.params.method === 'DELETE'
      ) {
        console.log(action);
        console.log(prevData);
        return prevData.filter(
          employee => employee._id !== action.request.pathvars.id
        );
      }
      data || (data = []);
      return data.employees;
    },
  },
  downloadsPerDay: {
    url: 'https://api.passpoints.de/api/passes/downloadsPerDay',
    options: getOptions,
    transformer: function companyTransformer(data, prevData, action) {
      data || (data = []);
      return data;
    },
  },
  points: {
    url: 'https://api.passpoints.de/api/passes/points',
    options: getOptions,
    transformer: function companyTransformer(data, prevData, action) {
      data || (data = 0);
      return data.points;
    },
  },
  downloads: {
    url: 'https://api.passpoints.de/api/passes/count',
    options: getOptions,
    transformer: function companyTransformer(data, prevData, action) {
      data || (data = 0);
      return data.count;
    },
  },
  transactions: {
    url: 'https://api.passpoints.de/api/transactions',
    options: getOptions,
    transformer: function companyTransformer(data, prevData, action) {
      data || (data = {});
      return data.transactions;
    },
  },
}).use('fetch', adapterFetch(fetch)); // it's necessary to point using REST backend
