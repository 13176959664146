import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Invoice extends Component {
  static propTypes = {
    company: PropTypes.object,
  };

  state = {
    save: false,
    loading: null,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const response = await fetch('https://api.passpoints.de/api/companies/me', {
      method: 'GET',
      headers: {
        token: this.props.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const body = await response.json();
    this.setState({ ...body.company.contact, loading: false });
  }

  save = async event => {
    event.preventDefault();

    this.setState({ save: true });

    const response = await fetch('https://api.passpoints.de/api/companies/me', {
      method: 'PUT',
      headers: {
        token: this.props.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company: {
          contact: this.state,
        },
      }),
    });

    if (response.status < 299) {
      this.setState({ save: false });
    }
  };

  render() {
    const {
      companyname,
      firstname,
      lastname,
      street,
      city,
      vat,
      phone,
      loading,
    } = this.state;

    if (loading === null || loading === true) {
      return null;
    }

    return (
      <form onSubmit={this.save}>
        <div className="grid gutter form-grid">
          <div className="grid-item x100">
            <fieldset>
              <h3>Firma</h3>
              <input
                value={companyname}
                onChange={event =>
                  this.setState({ companyname: event.target.value })
                }
                placeholder={'Firma'}
              />
            </fieldset>
          </div>
          <div className="grid-item x50">
            <fieldset>
              <h3>Vorname</h3>
              <input
                value={firstname}
                onChange={event =>
                  this.setState({ firstname: event.target.value })
                }
                placeholder={'Vorname'}
              />
            </fieldset>
          </div>
          <div className="grid-item x50">
            <fieldset>
              <h3>Nachname</h3>
              <input
                value={lastname}
                onChange={event =>
                  this.setState({ lastname: event.target.value })
                }
                placeholder={'Nachname'}
              />
            </fieldset>
          </div>
          <div className="grid-item x100">
            <fieldset>
              <h3>Straße</h3>
              <input
                value={street}
                onChange={event =>
                  this.setState({ street: event.target.value })
                }
                placeholder={'Straße'}
              />
            </fieldset>
          </div>
          <div className="grid-item x100">
            <fieldset>
              <h3>Stadt</h3>
              <input
                value={city}
                onChange={event => this.setState({ city: event.target.value })}
                placeholder={'Stadt'}
              />
            </fieldset>
          </div>
          <div className="grid-item x100">
            <fieldset>
              <h3>Telefon</h3>
              <input
                value={phone}
                onChange={event => this.setState({ phone: event.target.value })}
                placeholder={'Telefon'}
              />
            </fieldset>
          </div>
          <div className="grid-item x100">
            <fieldset>
              <h3>Umsatzsteuer-ID</h3>
              <input
                value={vat}
                onChange={event => this.setState({ vat: event.target.value })}
                placeholder={'Umsatzsteuer-ID'}
              />
            </fieldset>
          </div>
        </div>
        <div className="right">
          <button
            type="submit"
            className={this.state.save ? 'save-button inaktive' : 'save-button'}
          >
            Speichern
          </button>
        </div>
      </form>
    );
  }
}

function select(state) {
  return { token: state.login.token };
}

export default connect(select)(Invoice);
